.loading {



  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading-text {
    @apply text-xs text-opacity-30 py-4;
  }

}
