.reading-value {
  font-family: 'Oswald', sans-serif;
  font-size: 1.1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px 2px 2px 4px;

  .digit {
    line-height: 1.2em;
    text-align: center;
    background: #f1f1f1;
    color: #333;
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 2px;
    width: 0.9em;
    display: inline-block;

    &.postdecimal {
      color: #aa3300;
    }

    &.masked {
      color: #D1D5DB
    }
  }
}
