.dialog {
@apply fixed inset-0 overflow-y-auto flex;
  z-index: 20;

  .dialog--wrapper {

  @apply flex items-center justify-center place-content-center flex-1;

    .dialog--overlay {
    @apply fixed inset-0 bg-black opacity-40 flex;
    }

    .dialog--content {

    @apply flex flex-col fixed inset-0 top-16 md:top-0 z-20 bg-white p-2 sm:relative  sm:mx-auto sm:rounded-lg; //sm:w-3/4 sm:max-w-2xl

      @media (min-width: 640px) {
        max-width: calc(100vw - 40px);
        max-height: calc(100vh - 110px);
        margin-top: 65px;

      //  min-width: 640px;
      }

      max-height: calc(100vh - 4em);

      //max-width: 100vw;
      //max-height: calc(100vh - 65px);

      .dialog--body {
        overflow-y: auto;
        max-height: 100%;
      }

      .dialog--title {
      @apply text-2xl text-primary  mt-1 mb-2;
        min-height: 30px;
      }

      .dialog--description {
      @apply font-medium ;
      }

      .dialog--text {
        @apply mt-2 font-light;
      }

      .dialog--actions {
      @apply   mt-6 gap-2;
        .button {
        @apply flex-1;
        }
      }
    }
  }
}
