@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    /*@apply light;*/
    /*--test: rgb(31, 41, 55)*/
    /*--primary: 186, 218, 85;*/
    /*--secondary: 70, 88, 132;*/
    /*--accent: 219, 102, 50;*/
    /*--background: 68, 68, 68;*/
    /*--main: 51, 51, 51;*/
    /*!*--header: rgb(30, 131, 219);*!*/
    /*--header-text: 150, 174, 199;*/
}


/*.light {*/
/*    --primary: 186, 218, 85;*/
/*    --primary-text: '#fff';*/
/*    --secondary: #a0aec0;*/
/*    --accent: #718096;*/
/*    --background: #edf0f6;*/
/*    --main: #333;*/
/*    --header: #2d3748;*/
/*    --header-text: #a0aec0;*/
/*}*/

/*.dark {*/


/*}*/

html {
    /*overflow: auto !important;*/
    /*padding-right: 0 !important;*/
}

html, body {
    @apply w-full;
}

body {
    @apply bg-background text-main;
}

.dialog a {
    @apply text-accent border-accent border-dotted border-b;
}

.dialog a:hover {
    @apply border-accent-light border-solid text-accent-light;
}

.main--content-wrapper a{
    @apply text-primary border-primary border-dotted border-b;
}

.main--content-wrapper a:hover {
    @apply border-primary-light border-solid text-primary-light;
}

nav a, nav a:hover {
    @apply border-none;
}


::selection {
    @apply bg-primary text-white;
}

@layer base {
    h1 {
        @apply text-2xl font-medium text-gray-700;
    }

    h2 {
        @apply text-xl font-medium text-gray-600;
    }

    h3 {
        @apply text-lg font-semibold text-gray-600;
    }

    h4 {
        @apply text-base font-semibold text-gray-600 mt-2 mb-1;
    }

}

p.description {
    @apply text-gray-500 mb-3 leading-6;
    font-size: 0.9rem;
}
