.getContrastYIQ(@color) when (iscolor(@color))
{
  .return_contrast_color(@yiq) when (@yiq >= 128) { color: #000; }
  .return_contrast_color(@yiq) when (@yiq < 128)  { color: #fff; }
  @r: red(@color);
  @g: green(@color);
  @b: blue(@color);
  @yiq: ((@r*299)+(@g*587)+(@b*114))/1000;
  .return_contrast_color(@yiq);
}

button.button {
  @apply bg-accent cursor-pointer rounded border-2 border-transparent hover:bg-accent-light py-2 transition-colors focus:ring-0 outline-none my-1 sm:px-6;


  &:not(.button--ghost):not(.button--disabled){
    @apply text-gray-600;
  }

  &:not(.button--ghost):not(.button--disabled) {
    .button-text-wrapper {

      //color: #ffffff;
      //@apply text-primary;
      color: var(--accent);
      filter: contrast(1000%) saturate(0) invert(1);
    }
  }

  &--primary {
  @apply bg-primary hover:bg-primary-light;

    &:not(.button--ghost):not(.button--disabled) {
      .button-text-wrapper {
        //color: #ffffff;
        //@apply text-primary;
        color: var(--primary);
        filter: contrast(1000%) saturate(0) invert(1);
      }
    }
    //.getContrastYIQ(primary)
  }

  &--accent {
  @apply bg-accent hover:bg-accent-light;

    &:not(.button--ghost):not(.button--disabled) {
      .button-text-wrapper {
        //color: #ffffff;
        //@apply text-primary;
        color: var(--primary);
        filter: contrast(1000%) saturate(0) invert(1);
      }
    }
    //.getContrastYIQ(primary)
  }

  &--ghost {
    &:not(.button--primary){
    @apply bg-transparent border-2 border-gray-200 hover:bg-transparent hover:text-gray-500 hover:border-gray-100  box-border;
    }

    &.button--primary {
      @apply bg-transparent  border-primary hover:border-primary-lighter hover:text-primary-lighter text-primary;

    }
  }


  &--secondary {
    @apply bg-secondary  hover:bg-secondary-light;
    //background-color: black;

    .button-text-wrapper {
      //color: contrast(var(--secondary));
      @apply text-secondary;
      filter: contrast(1000%) saturate(0) invert(1) ;
      //mix-blend-mode: difference;
      //color: #ccc;
      //color: color-contrast(#993300 vs #000000, #ffffff);
      //color: #f1f1f1;
      //mix-blend-mode: difference;
      //filter: grayscale(0%);
      //filter: hue-rotate(90deg) grayscale(1) brightness(100);
    }
  }



  &--disabled {
    @apply bg-gray-200 hover:bg-gray-200 text-gray-400 cursor-not-allowed;
  }

  &--icon {
    @apply p-1 text-sm;

    .icon {
     margin-right: 0px !important;
    }
  }

  &--small {
    @apply text-sm p-2 sm:p-1 sm:px-4;

    .icon {
      @apply mr-2;
    }
  }

  .icon {
  @apply h-4 w-4 mr-1;
  }


  &:not(.button--small):not(.button--icon){
    .icon {
    @apply h-6 w-6;
    }
  }

}

