.form-input {
  -webkit-appearance: none;
  box-shadow: none !important;
  background-clip: padding-box;

}

.form-input[type=text] {
  -webkit-appearance: none;
  box-shadow: none !important;
  background-clip: padding-box;

}
