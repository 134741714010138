.footer {

  a {
    @apply border-0 text-current ;

    &:hover {
      @apply border-b text-primary;
    }
  }



}
