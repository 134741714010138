.sidebar {

  width: 300px;

  @media (min-width: 700px) {
    width: 350px;
  }

  @media (min-width: 900px) {
    width: 400px;
  }

}

.sidebar-main {
  margin-left: 300px;

  @media (min-width: 700px) {
    margin-left: 350px;
  }

  @media (min-width: 900px) {
    margin-left: 400px;
  }
}
