.meter-reading-input {
  font-family: "Oswald", sans-serif;
  font-size: 1.5em;
  line-height: 1.5em;
  display: inline-flex;

  input, .decimal-separator {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    display: inline-block;
    letter-spacing: 0.2em;

    &::placeholder {
      color: #D1D5DB;
    }

    &:focus {
      outline: none;
    }
  }

  .pre-decimal-input {
    padding-left: 0.25em;
    text-align: right;
    border-left: 5px solid black;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    border-radius: 5px 0 0 5px;
    height: 100%;

    &.only-predecimals {
      border-right: 5px solid black;
      padding-right: 0.25em;
    }
  }

  .decimal-separator {
  @apply text-gray-500;

    letter-spacing: 0;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    display: inline-block;
  }

  .post-decimal-input {
    height: 100%;
    padding-right: 0.1em;
    border-radius: 5px;

    border-right: 5px solid red;
    border-top: 5px solid red;
    border-bottom: 5px solid red;
  }
}
