.markdown-navigation-sidebar {
  div {
    width: inherit;
  }
  .help-menu-entry {
    width: auto;
    a {
    @apply text-gray-800 border-0;
      &:hover {
        @apply text-primary;
      }
    }
  }

  .help-menu-entry-1 {
  @apply font-semibold;
  }
}
