.toolbar {
  h2 {

    @media (max-width: 500px) {
      font-size: 1rem;
      line-height: 1.1rem;

    }
  }
}

.dialog .toolbar--actions {
  margin-right: 50px;
}
