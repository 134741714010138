.job-details-add-unknown {

}

.job-details-add-unknown-meter-number-input {
  font-family: "Oswald", sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
  display: inline-flex;
  letter-spacing: 0.2em;
  padding: 0.25em;
  text-align: right;
  height: 100%;
  width: 264px;

  &::placeholder {
    color: #D1D5DB;
  }
}