.input-emails {

  .token-input-container {
    height: 100%;
  }

  .token-input-container .token-input-token-list .token-input-token.token-input-token--error {

  @apply bg-red-100 text-red-900;
  }

  .token-input-container .token-input-token-list {

  }

  .token-input-container .token-input-token-list .token-input-token {
    margin: 0 4px 0 0 ;




  @apply bg-gray-100 text-primary-200;
  }

  .token-input-container.token-input-container--errors {
  @apply border-yellow-700;
  }
}
