.markdown-viewer {

  @apply pb-4;
  font-size: 0.95rem;

  h3, h4, h5 {
    &:before {
      display: block;
      content: " ";
      margin-top: -65px;
      height: 65px;
      visibility: hidden;
    }

    line-height: 2rem;
  }

  h3 {
    &:first-of-type {
      margin-top: 1.5rem;
    }
  }

  h3 + h4 {
    margin-top: 0;
  }

  h4 + h5 {
    margin-top: 0;
  }

  h3 {
    font-size: 1.6em;
  @apply text-gray-600 mt-12 mb-6;
  }

  h4 {
    font-family: unset;
    font-size: 1.5em;
  @apply text-gray-500 mt-12 mb-2 font-bold;
  }

  h5 {
    font-size: 1.2em;
  @apply text-gray-500 mt-6 mb-2 font-bold;
  }

  ul {
  @apply ml-7 list-disc list-outside text-left -mt-2 mb-2;

    li {
    @apply mt-1;
    }
  }

  ol {
  @apply list-decimal list-outside text-left -mt-2 mb-2 ml-7;

    li {
    @apply mt-1;
    }
  }

  p {
  @apply mb-4;
  }

  img {
  @apply my-2;
  }

  .collapseable {
  }

  .collapsable-trigger {
  @apply bg-primary-lighter rounded mt-1;
  }

  .collapsable-content {
  @apply bg-background;
    hyphens: auto;
  }

  .collapsable-content-open {
  }

  p {
    text-align: justify;
  }
}

.markdown-viewer-light {

}
