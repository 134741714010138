.card {
@apply p-2 rounded ;

  &--active {
    @apply bg-white shadow-md transform translate-x-1 ;
  }

  h1, h2 {
    margin-bottom: 1.25rem;
  }

  h2 {

  }
}


.card {
  .card {
    @apply border-gray-100 border shadow-sm;

  }
}
