.main-screen {

  @apply bg-background min-h-screen ;

  .main--content-wrapper {
  @apply lg:container max-w-screen-xl md:mx-auto bg-gray-100 rounded-md min-w-full lg:min-w-min;

  }

}
