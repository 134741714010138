.envago-table {

  //height: 600px;
  height: 100%;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    font-family: arial, sans-serif;
    table-layout: auto;
    width: 100%;

  }

  thead {
    //background: lightgray;
    background-color: #fff;
    margin: 0;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  th {
    //border-bottom: 1px solid lightgray;
    //border-right: 1px solid lightgray;
    text-align: left;

  }

  td {
    padding: 6px;
  }

  .table-container {
    //border: 1px solid lightgray;
    height: 100%;

    //max-width: 1400px !important;

    //width: fit-content;
    max-width: 100%;

    &.full-width {
      min-width: calc(100vw - 100px);
    }

    overflow: auto;
  }

}
