.share-job-dialog {

}

.dialog-content {
  width: 100%;
  max-width: 1024px !important; /* Adjust max-width as per your requirement */
  margin: 0 auto; /* Center the dialog horizontally */
}

.primary-login-sharing-method {
  > .label {
  @apply self-start mt-2;
  }

}
